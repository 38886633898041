<template>

<div v-loading.fullscreen.lock="fullscreenLoading">
  <div class="login-box" style="height:300px; padding-top:30px; padding-bottom:30px;">
    <el-form ref="loginForm"  label-width="0px">
        <div style="padding-bottom:15px;">
            <h3><i class="el-icon-user-solid"></i> <span>欢迎登录IOS分发管理系统</span></h3>
        </div>
        <div style="clear:both; padding-top:10px;  padding-right:20px; ">
            <el-form-item label="" prop="username">
                <el-input type="text" placeholder="请输入账号/手机号码" prefix-icon="el-icon-user"  v-model="txtLoginName" />
            </el-form-item>
            <el-form-item label="" prop="password">
                <el-input type="password" prefix-icon="el-icon-unlock" placeholder="请输入密码" v-model="txtLoginPwd" />
            </el-form-item>


            <el-form-item label="" class="putBtn" style="text-align: right;">
                <el-button style="width:40%;" type="primary" @click="onLogin()">登录</el-button>
            </el-form-item>

        </div>
    </el-form>

  </div>

</div>

</template>
<script>
  export default {
    data() {
      return {
        // 登录帐号
        txtLoginName: '',
        // 登录密码
        txtLoginPwd: '',
        // 验证提示语
        tipErr: '',
        fullscreenLoading: false,
        currentUser:
        {
          token:null,
          userName:null,
          userId:null,
          name:null,
          actions:null,
          menus:null,
        }
      };
    },
    created() {
      let that = this;

      localStorage.clear();
      sessionStorage.clear();
      var currentUser = JSON.parse(that.$GetKey('currentUser'));
      if(currentUser!=null && currentUser.token != null && typeof(currentUser.token)!="undefined" && currentUser.token != "")
      {
        that.$router.replace('/Admin/Interface/index');
      }
    },
    mounted() {
    },
    methods: {
      onLogin() {
        // 验证登录用户名和密码是否为空
        let that = this;

        // MD5 加密
        var password = that.$md5(that.txtLoginPwd);
        //赋值
        let data = {
          "username": that.txtLoginName,
          "password": password,
        };
        that.fullscreenLoading = true;
        that.tipErr = "";
        ajaxLogin(that, "/api/auth/Token", data, function(res) {
          that.fullscreenLoading = false;
          if (res.data.data.success != false) {

            localStorage.clear();
            sessionStorage.clear();
            that.currentUser.token = res.data.data.accessToken;
            that.currentUser.name = res.data.data.name;
            that.currentUser.userName = res.data.data.userName;
						that.currentUser.userType = res.data.data.userType;
						that.currentUser.userId = res.data.data.userId;
            localStorage.setItem("currentUser",JSON.stringify(that.currentUser));
            that.$router.replace('/Admin/Interface/index');
          }
          else{
            warning(that,res.data.data.message);
          }
        });
      },
    },
    beforeDestroy() {
        document.querySelector('body').removeAttribute('style');
    },
  };
</script>
<style lang="less" scoped="scoped">
   .login-box {
            border: 1px solid #DCDFE6;
            width: 540px;
            margin: 180px auto;
            padding: 35px 35px 15px 35px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            box-shadow: 0 0 25px #909399;
        }

        .login-title {
            text-align: center;
            width: 100%;
            /*margin: 0 auto 40px auto;*/
            color: #303133;
        }
</style>
